import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Switch, Tag, Typography } from 'antd';

import { Div } from '../../../framework';
import { setFlyoutOpen, setMenuMode } from '../../../store/app.slice';
import { setPartialSettings } from '../../../store/user.slice';
import { RootState } from '../../../store/store';
import { useSettingsService } from '../../../service/settings.service';
import WhatsappNumberForm from './WhatsappNumberForm';

const { Title } = Typography;

const SettingsPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((state: RootState) => state.user);
  const settingsService = useSettingsService();

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  const handleToggleSettings = async (
    settingAttribute: string,
    checked: boolean
  ) => {
    try {
      const updatedValues = { [settingAttribute]: checked };
      await settingsService.updateSettings(updatedValues);
      dispatch(setPartialSettings(updatedValues));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Div ph='s' style={{ width: '100%' }}>
      <Title level={3}>Settings</Title>
      <Form>
        <Form.Item
          name='isTelegramNotificationOn'
          label='Telegram notification'
          labelAlign='left'
          labelCol={{ flex: 'auto' }}
          wrapperCol={{ flex: 'none' }}
        >
          <Switch
            disabled={!settings.hasActivateTelegram}
            checked={
              settings.hasActivateTelegram && settings.isTelegramNotificationOn
            }
            checkedChildren='ON'
            unCheckedChildren='OFF'
            onChange={(checked) =>
              handleToggleSettings('isTelegramNotificationOn', checked)
            }
          />
        </Form.Item>
        <Form.Item
          name='isWhatsappNotificationOn'
          label='WhatsApp notification'
          labelAlign='left'
          labelCol={{ flex: 'auto' }}
          wrapperCol={{ flex: 'none' }}
          style={{ marginBottom: '0.25rem' }}
        >
          {settings.isWhatsappVerified ? (
            <Tag color='#16B55E'>Verified</Tag>
          ) : (
            <Tag color='#777777'>Not verified</Tag>
          )}
          <Switch
            disabled
            checked={settings.isWhatsappNotificationOn}
            checkedChildren='ON'
            unCheckedChildren='OFF'
            onChange={(checked) =>
              handleToggleSettings('isWhatsappNotificationOn', checked)
            }
          />
        </Form.Item>
      </Form>

      <WhatsappNumberForm />
    </Div>
  );
};

export default SettingsPage;
