import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Rate } from 'antd';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import { supportEmail } from '../../../../config/app.config';
import ReplyCommentButton from '../../../component/RatingComment/ReplyCommentButton';
import { Div } from '../../../framework';
import CommentSvg from '../../../framework/Icon/CommentSvg';
import { Rating } from '../../../helper/type';
import { getComposeEmailUrl } from '../../../helper/getComposeEmailUrl';
import { useRatingService } from '../../../service/rating.service';
import { RootState } from '../../../store/store';

import './RatingListItem.scss';

type Props = {
  rating: Rating;
};

const RatingListItem: FC<Props> = ({ rating }) => {
  const history = useHistory();
  const ratingService = useRatingService();
  const { authUser, profilePictureUrl, impersonator, talent } = useSelector(
    (state: RootState) => state.user
  );

  const [truncateReview, setTruncateReview] = useState(
    rating.writtenReview && rating.writtenReview?.length > 140
  );

  const isImpersonator =
    impersonator.impersonateId && impersonator.impersonateKey;
  const talentId =
    authUser.username || (isImpersonator ? impersonator.impersonateId : '');

  const commentCount = useMemo(() => {
    let totalCount = rating.comments.length;
    rating.comments.forEach(
      (comment) => (totalCount += comment.replies.length)
    );
    return totalCount;
  }, [rating]);

  const submitComment = async (comment: string) => {
    await ratingService.createComment({
      ratingId: rating.ratingId,
      comment,
      authorType: 'talent',
      authorName: talent.name || authUser.name,
      talentId,
      roleId: rating.roleId,
      authorProfilePicThumb: profilePictureUrl,
    });
    history.push(`/talent/ratings/role/${rating.roleId}/comments`);
  };

  return (
    <Div className='rating-list-item'>
      <Div flex className='author-container'>
        <img
          src={rating.authorProfilePicThumb}
          className='author-profile-pic'
        />
        <Div>
          <Div className='author-name'>{rating.authorCompanyName}</Div>
          <Div className='comment-date'>
            {format(new Date(rating.createdAt), 'dd MMM yyyy')}
          </Div>
        </Div>
        <a
          href={getComposeEmailUrl({
            recipient: supportEmail,
            subject: `Report comment - ${rating.ratingId}`,
          })}
          className='text-red'
        >
          Report
        </a>
      </Div>
      <Div className='rating-hire-again'>
        {rating.hireAgain ? (
          <>
            <CheckCircleFilled className='text-green' /> Will hire again
          </>
        ) : (
          <>
            <CloseCircleOutlined className='text-red' /> Will not hire again
          </>
        )}
      </Div>
      <Div>
        <Div flex className='rating-star-item'>
          <span className='item-label'>Punctuality</span>
          <Rate
            count={rating.punctuality}
            defaultValue={rating.punctuality}
            disabled
            className='rating-stars'
          />
        </Div>
        <Div flex className='rating-star-item'>
          <span className='item-label'>Professionalism</span>
          <Rate
            count={rating.professionalism}
            defaultValue={rating.professionalism}
            disabled
            className='rating-stars'
          />
        </Div>
        <Div flex className='rating-star-item'>
          <span className='item-label'>Responsiveness</span>
          <Rate
            count={rating.responsiveness}
            defaultValue={rating.responsiveness}
            disabled
            className='rating-stars'
          />
        </Div>
        <Div flex className='rating-star-item'>
          <span className='item-label'>Dialogue Mastery</span>
          <Rate
            count={rating.dialogueMastery}
            defaultValue={rating.dialogueMastery}
            disabled
            className='rating-stars'
          />
        </Div>
        <Div flex className='rating-star-item'>
          <span className='item-label'>Profile Representation</span>
          <Rate
            count={rating.profileRepresentation}
            defaultValue={rating.profileRepresentation}
            disabled
            className='rating-stars'
          />
        </Div>
      </Div>
      <Div mt='xl' mb='l' className='written-review'>
        {truncateReview ? (
          <>
            {`${rating.writtenReview?.substring(0, 141)}...`}
            <Button
              type='link'
              className='p-0'
              onClick={() => setTruncateReview(false)}
            >
              more
            </Button>
          </>
        ) : (
          rating.writtenReview
        )}
      </Div>
      {commentCount > 0 ? (
        <Button
          type='text'
          className='btn-view-comment'
          onClick={() =>
            history.push(`/talent/ratings/role/${rating.roleId}/comments`)
          }
        >
          <CommentSvg /> {commentCount}{' '}
          {commentCount > 1 ? 'comments' : 'comment'}
        </Button>
      ) : (
        <ReplyCommentButton onSubmit={submitComment} />
      )}
    </Div>
  );
};

export default RatingListItem;
