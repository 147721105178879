import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Breadcrumb, Dropdown, Button as AntdButton } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Div, Button } from '../../../framework';
import { Rating } from '../../../helper/type';
import { useRatingService } from '../../../service/rating.service';
import { setRatings } from '../../../store/talent.slice';
import { RootState } from '../../../store/store';
import { openModal, setFlyoutOpen, setMenuMode } from '../../../store/app.slice';
import RatingListItem from './RatingListItem';

import './Ratings.scss';

const { Title, Paragraph } = Typography;

const TalentRatingsPage = () => {
  const dispatch = useDispatch();
  const { loadStack } = useSelector((state: RootState) => state.app);
  const { authUser, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const { mappedRatings } = useSelector((state: RootState) => state.talent);
  const ratingService = useRatingService();

  const [listKey, setListKey] = useState<string>();
  const [sortBy, setSortBy] = useState('latest');

  const isImpersonator =
    impersonator.impersonateId && impersonator.impersonateKey;
  const talentId =
    authUser.username || (isImpersonator ? impersonator.impersonateId : '');
  const isLoading = loadStack.includes('rating/talent/list');
  const sortByMenu = [
    { key: 'latest', label: 'Latest' },
    { key: 'oldest', label: 'Oldest' },
    { key: 'highest', label: 'Highest Rating' },
    { key: 'lowest', label: 'Lowest Rating' },
  ];

  const sortedRatings = useMemo(() => {
    const ratings = Object.values(mappedRatings[talentId] ?? {}).filter(
      (rating) => rating.overallPerformance != undefined
    );

    if (sortBy === 'oldest') {
      return ratings.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
    }

    const sumRating = (r: Rating) => {
      return (
        (r.punctuality ?? 0) +
        (r.professionalism ?? 0) +
        (r.responsiveness ?? 0) +
        (r.dialogueMastery ?? 0) +
        (r.profileRepresentation ?? 0) +
        (r.overallPerformance ?? 0)
      );
    };
    if (sortBy === 'highest') {
      return ratings.sort((a, b) => sumRating(b) - sumRating(a));
    }
    if (sortBy === 'lowest') {
      return ratings.sort((a, b) => sumRating(a) - sumRating(b));
    }

    return ratings.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
  }, [sortBy, mappedRatings]);

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  useEffect(() => {
    (async () => {
      const { data, key } = await ratingService.listRatingsByTalent(talentId);
      setListKey(key);
      dispatch(setRatings({ talentId, ratings: data }));
    })();
  }, []);

  const handleLoadMore = async () => {
    if (!listKey) return;

    const { data, key } = await ratingService.listRatingsByTalent(
      talentId,
      listKey
    );
    setListKey(key);
    dispatch(setRatings({ talentId, ratings: data }));
  };

  return (
    <Div ph='l' className='page-talent-ratings'>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`/t/${talentId}`}>My Profile</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Ratings</Breadcrumb.Item>
      </Breadcrumb>

      <Div flex className='title-container'>
        <Div>
          <Title level={3} className='title'>
            Ratings
          </Title>

          <Paragraph className='m-0'>{sortedRatings.length} ratings</Paragraph>
          <AntdButton
            type='link'
            className='p-0'
            onClick={() =>
              dispatch(
                openModal({
                  modal: 'talent-rating-desc',
                  config: {
                    modalProps: {
                      footer: null as any,
                      closable: true,
                      centered: true,
                    },
                  },
                })
              )
            }
          >
            <span className='underline'>How ratings work</span>
          </AntdButton>
        </Div>
        <Dropdown
          trigger={['click']}
          placement='bottomRight'
          openClassName='dropdrop'
          overlayClassName='rating-comment-dropdown-overlay'
          menu={{
            items: sortByMenu.map(({ key, label }) => ({
              key,
              label,
              onClick: () => setSortBy(key),
            })),
          }}
        >
          <AntdButton type='link' className='p-0'>
            Sort by {sortByMenu.find((item) => item.key === sortBy)?.label}{' '}
            <DownOutlined />
          </AntdButton>
        </Dropdown>
      </Div>

      <Div className='rating-list'>
        {sortedRatings.map((rating) => (
          <RatingListItem key={rating.ratingId} rating={rating} />
        ))}
      </Div>

      {listKey && !isLoading ? (
        <Button mh='s' block onClick={handleLoadMore}>
          Load More
        </Button>
      ) : null}
    </Div>
  );
};

export default TalentRatingsPage;
