import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Typography, Breadcrumb } from 'antd';
import { differenceInHours } from 'date-fns';
import { supportEmail } from '../../../../config/app.config';
import RatingCommentList from '../../../component/RatingCommentList/RatingCommentList';
import { Div } from '../../../framework';
import { useResetScroll } from '../../../helper/hook';
import { getComposeEmailUrl } from '../../../helper/getComposeEmailUrl';
import { Rating } from '../../../helper/type';
import { useRatingService } from '../../../service/rating.service';
import { RootState } from '../../../store/store';
import { setMenuMode } from '../../../store/app.slice';

import './ViewComments.scss';

const { Title } = Typography;

const ViewCommentsPage = () => {
  const dispatch = useDispatch();
  useResetScroll();
  const ratingService = useRatingService();
  const { roleId } = useParams<Record<string, string>>();
  const { authUser, impersonator } = useSelector(
    (state: RootState) => state.user
  );

  const [currentRating, setCurrentRating] = useState<Rating>();

  const isImpersonator =
    impersonator.impersonateId && impersonator.impersonateKey;
  const talentId =
    authUser.username || (isImpersonator ? impersonator.impersonateId : '');

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  const fetchRating = async () => {
    const rating = await ratingService.getRating(talentId, roleId, true);
    setCurrentRating(rating);
  };

  return (
    <Div className='page-talent-view-comments'>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`/t/${talentId}`}>My Profile</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to='/talent/ratings'>Ratings</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>View Comments</Breadcrumb.Item>
      </Breadcrumb>

      <Div className='title-container'>
        <Title level={3} className='title'>
          View Comments
        </Title>
        {currentRating &&
          differenceInHours(new Date(), new Date(currentRating.createdAt)) <
            36 && (
            <a
              href={
                currentRating &&
                getComposeEmailUrl({
                  recipient: supportEmail,
                  subject: `Report comment - ${currentRating.ratingId}`,
                })
              }
              className='btn-report-issue'
            >
              Report issue
            </a>
          )}
      </Div>

      <RatingCommentList
        userType='talent'
        currentRating={currentRating}
        fetchRating={fetchRating}
      />
    </Div>
  );
};

export default ViewCommentsPage;
