import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Typography } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Div } from '../../../../framework';
import { useSettingsService } from '../../../../service/settings.service';
import { RootState } from '../../../../store/store';
import { setMenuMode } from '../../../../store/app.slice';

const { Title } = Typography;

const VerifyWhatsappPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const { loadStack } = useSelector((state: RootState) => state.app);
  const settingsService = useSettingsService();
  const searchParam = new URLSearchParams(search);
  const prefix = searchParam.get('prefix');
  const phone = searchParam.get('phone');
  const code = searchParam.get('code');
  const loopback = searchParam.get('loopback');

  const isLoading = loadStack.includes('settings/whatsapp/verify');

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  useEffect(() => {
    if (!prefix || !phone || !code || isLoading) return;

    (async () => {
      await settingsService.whatsappVerifyRequest(prefix, phone, code);

      if (loopback) {
        history.replace(loopback);
      }
    })();
  }, [prefix, phone, code]);

  return (
    <Div ph='s' style={{ width: '100%' }}>
      <Title level={3}>WhatsApp Verification {!isLoading && 'Complete'}</Title>

      {isLoading && (
        <Div flex style={{ justifyContent: 'center', marginTop: '2rem' }}>
          <Spin spinning tip='Verifying...' />
        </Div>
      )}
      {!isLoading && (
        <Div>
          Great! 🎉 We&apos;ll keep you updated on your job application status
          through WhatsApp. For an even smoother experience, download our{' '}
          <Link to={'/talent/prompt-install'}>web app</Link> today and manage
          everything easily in one place. 😊
        </Div>
      )}
    </Div>
  );
};

export default VerifyWhatsappPage;
